* {
  padding: 0;
  margin: 0;
  font-family: "Courier New", Courier, monospace !important;
}

body {
  position: relative;
  padding-top: 0px; /* fixed-nav-bar */
  background-color: red !important;
}

h4 {
  font-size: 18px !important;
  font-weight: bold !important;
}

h5 {
  font-size: 15px !important;
  font-weight: bold !important;
}

nav {
  background-color: rgba(0, 0, 0, 0) !important;
  text-align: center;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.navbar-toggler:active {
  transform: scale(1.1);
  filter: drop-shadow(5px 5px 7px blue);
  -webkit-filter: drop-shadow(5px 5px 7px blue);
}

.nav-current {
    opacity: 0;
}

.nav-link {
  background-color: rgba(0, 0, 0, 0.9); 
}

.nav-link:hover {
  transform: scale(1.1);
  filter: drop-shadow(5px 5px 7px blue);
  -webkit-filter: drop-shadow(5px 5px 7px black);
}

.nav-item {
  animation: 2s linear blink;
  animation-iteration-count: 1;
  border-radius: 30px;
}

